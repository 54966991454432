import './styles.scss';

const HeaderError = ({ error }) => {
    return (
        <div className="error-404-header">
            {!error ? (
                <>
                    <div className="error-404-code">404</div>
                    <div className="error-404-text">HTTP Error 404</div>
                    <div className="error-404-description">Page not found</div>
                </>
            ) :
                <div className="error-404-custom">{error}</div>
            }
        </div>
    );
}

export default HeaderError;
