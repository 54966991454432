import Footer from '../Footer';
import HeaderError from './HeaderError';
import './styles.scss';

const Error = ({ error }) => {
  return (
    <div className="error-container">
      <HeaderError error={error} />
      <Footer />
    </div>
  );
}

export default Error;
