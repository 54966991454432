import { Preloader as PreloaderIcon, Puff } from 'react-preloader-icon';
import React from 'react';
import './styles.scss';

const Preloader = () => (
  <div className="mainPreLoaderParent">
    <div className="mainPreLoader">
      <PreloaderIcon
        use={Puff}
        size={70}
        strokeWidth={15}
        strokeColor="#f7bd27"
        duration={800}
      />
    </div>
  </div>
);

export default Preloader;
