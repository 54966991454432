import Error from './Error';
import Auth from './Auth';
import Redirect from './Redirect'
/**
 * List of all routes.
 */
const routes = [
  {
    path: '/auth',
    exact: true,
    element: <Auth />,
    key: 'auth'
  },
  {
    path: '/',
    exact: true,
    element: <Redirect />,
    key: 'root'
  },
  {
    path: '*',
    element: <Error />,
    key: 'error'
  }
];

export default routes;
