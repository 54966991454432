import { SOLAR_MANAGER_DOMAIN } from '../constants';
import Error from '../Error';
import Preloader from '../Preloader';

const Auth = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  if (token) {
    window.location.replace(`${SOLAR_MANAGER_DOMAIN}/auth/sso/burkhalter/?token=${token}`);
  }
  const error = queryParams.get("error");
  if (error) {
    return <Error error={error} />
  }
  return <Preloader />
}

export default Auth;
